.domains {
  position: relative;
}

.domains-text {
  position: absolute;
  font-size: 5rem;
  font-weight: 700 !important;
  transform: rotate(270deg);
  top: 45%;
  right: 80%;
  color: #078080;
  }

  .dom-img {
    width: 70%;
    height: 70%;
    display: flex;
    margin: 0.5rem;
  }
  
  .dom-text {
    position: absolute;
    top: 75%;
    left: 55%;
    transform: translate(-50%, -50%);
    font-weight: 600 !important;
  }

  .tba {
      font-size: 4rem;
      padding: 4rem;
  }
 
  #apply {
    border: 2px solid black;
    background-color: #f45d48;
  }  

  
  @media only screen and (max-width: 1400px)
  {
    .domains-text {
      position: unset;
      transform: rotate(0deg);
      font-size: 5rem;
      font-weight: 700 !important;
      color: #078080;
    }

    .tba {
      text-align: center;
      font-size: 3.5rem;
      padding: 0;
    }
  }

  @media only screen and (max-width: 786px)
{
    .domains-text {
      font-size: 3rem;
    }
}

  
  .flip-card-project {
    border: 2px solid black;
    height: 280px;
    border-radius: 1rem;
    background: linear-gradient(-45deg,  #f8f5f2 0%, #f45d48 100%);
  }
