.navbar {
    background-color: rgb(244, 93, 72, 0.3);
    backdrop-filter: blur(15px);
    color: #f8f5f2;
  }
  
  .nav-links {
    margin-right: 3rem;
  }
  
  .nav-name {
      color: #f45d48;
  }

  .nav-toggles {
    color: #078080 !important;
  }
  
  .nav-toggles:hover {
      transform: scale(1.1);
      transition: 0.2s ease;
  }
  
  @media only screen and (max-width: 786px)
  {
    .nav-name {
        display: none;
    }
  
    .nav-links {
      margin-right: unset;
    }
  
    .toggler {
      border: none;
    }
  }
  