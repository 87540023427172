.projects {
  position: relative;
}

.projects-text {
  position: absolute;
  font-size: 5rem;
  font-weight: 700 !important;
  transform: rotate(270deg);
  top: 40%;
  right: 80%;
  color: #078080;
  }
  

  .tba {
      font-size: 4rem;
      padding: 4rem;
  }
 
  #apply {
    border: 2px solid black;
    background-color: #f45d48;
  }  

  
  @media only screen and (max-width: 1400px)
  {
    .projects-text {
      position: unset;
      transform: rotate(0deg);
      font-size: 5rem;
      font-weight: 700 !important;
      color: #078080;
    }

    .tba {
      text-align: center;
      font-size: 3.5rem;
      padding: 0;
    }
  }

  @media only screen and (max-width: 786px)
{
    .projects-text {
      font-size: 3rem;
    }
}

  
  .flip-card-project-proj {
    background-color: transparent;
    width: 100%;
    height: 300px;
    perspective: 1000px;
  }
  
  .flip-card-inner-project {
    position: relative;
    width: 100%;
    height: 100%;
    text-align: left;
    transition: transform 0.6s;
    transform-style: preserve-3d;
  }
  
  /* .flip-card-project-proj:hover .flip-card-inner-project {
    transform: rotateY(180deg);
  } */
  
  .flip-card-front-project-fs, .flip-card-front-project-algo, .flip-card-front-project-ml, .flip-card-front-project-arvr, .flip-card-front-project-os, .flip-card-front-project-app, .flip-card-front-project-ai, .flip-card-back-project{
    position: absolute;
    width: 100%;
    height: 100%;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    border-radius: 20px;
  }
  
  .flip-card-front-project-fs {
    background: url("../../project-img/Coding-project.svg");
    background-color:  rgb(232, 145, 52) ;
    background-size: 100% 100%;
    color: white;
    padding: 1rem;
  }

  .flip-card-front-project-algo {
    background: url("../../project-img/algorithm-project-01.svg");
    background-color: #d4ecff;
    background-size: 100% 100%;
    color: white;
    padding: 1rem;
  }

  .flip-card-front-project-ml {
    background: url("../../project-img/ML-project-01.svg");
    background-color: #f4413c;
    background-size: 100% 100%;
    color: white;
    padding: 1rem;
  }

  .flip-card-front-project-arvr {
    background: url("../../project-img/ArVr-project-01.svg");
    background-color: #a430a5;
    background-size: 100% 100%;
    color: white;
    padding: 1rem;
  }

  .flip-card-front-project-os {
    background: url("../../project-img/Operating\ system-project.svg");
    background-color: #c77309;
    background-size: 100% 100%;
    color: white;
    padding: 1rem;
  }

  .flip-card-front-project-app {
    background: url("../../project-img/app\ development-project.svg");
    background-color: #3bb4bc;
    background-size: 100% 100%;
    color: white;
    padding: 1rem;
  }

  .flip-card-front-project-ai {
    background: url("../../project-img/Artificial\ intelligence-project.svg");
    background-color: #7693a0;
    background-size: 100% 100%;
    color: white;
    padding: 1rem;
  }

  .flip-card-back-project {
    background: black;
    border: 2px solid white;
    color: white;
    padding: 3rem;
    transform: rotateY(180deg);
    font-family: 'DM Sans', sans-serif;
  }
  
  .header2 {
    position: relative;
    overflow: hidden;
  }
  
  .header2::before {
    position: absolute;
    top: 75%;
    right:15%;
    overflow: hidden;
    width: 80rem;
    height: 3px;
    content: '\a0';
    background: linear-gradient(-45deg, #ee7752, #e73c7e, #23a6d5, #23d5ab);
    background-size: 200% 200%;
    animation: gradient 5s ease infinite;
  }
  
  @media only screen and (max-width: 1200px)
  {
    .header2 {
      text-align: center !important;
    }
    .header2::before {
        display: none;
    }
  }
  
  @keyframes gradient {
    0% {
      background-position: 0% 50%;
    }
    50% {
      background-position: 100% 50%;
    }
    100% {
      background-position: 0% 50%;
    }
  }
  
  .mainhead1-project {
    
    color: white;
    font-weight: 700 !important;
    text-shadow: 4px 4px 2px black;
    -webkit-text-stroke-width: 1px;
    -webkit-text-stroke-color: black;
    font-size: 2vmax;
    text-align: center;
    /* font-weight: 600 !important; */
    position: absolute;
    top: 50%;
    left: 0%;
    transform: translateY(-65%);
  }

  .sawo {
    color: black;
  }

  .mainhead2-project {
    text-align: center;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
  }
  
  .svg-img {
  position: absolute;
  bottom: -10%;
  right: 0;
  }
  
  
  .svg-img1 {
  position: absolute;
  bottom: -10%;
  right: -15%;
  }
  
  @keyframes shine {
    0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
  }
  
  
  @media only screen and (max-width: 786px)
  {
  
  .flip-card-back-project h4 {
    font-size: 1.2rem;
  }
  
  .flip-card-back-project {
    padding: 2rem;
  }
  }