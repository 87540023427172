@import url(https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600;700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Lato:wght@400;700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=DM+Sans:wght@400;500;700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;600&display=swap);
html {
  scroll-behavior: smooth;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.App {
  text-align: center;
  width: 100%;
  background-color: #f8f5f2;
}
* {
  box-sizing: border-box;
  font-weight: 500 !important;
}

h1,
h2 {
  font-family: "Poppins", sans-serif;
  margin: 1.25rem;
  /* added to to fix header above the footer on landing pg.*/
}

h3 {

  font-family: 'DM Sans', sans-serif;
}

hr.divver {
  
  border: 1px solid #078080;
  width: 90%;
}


.landing {
  height: 100vh;
  background-color: #f8f5f2;
  color: #222525;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
  /*added to prevent user select on the main hero/landing viewport */
  text-align: left;
  margin: 0 4rem;
}

.particles {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -10;
}

.topline {
  padding-top: 20vh;
  font-size: 2vmax;
  color: #078080;
  font-weight: 600 !important;
}

.Landingimg-img {
  margin-top: 15rem;
}

.mainhead {
  font-size: 6vmax;
  font-weight: 800 !important;
  color: #F45D48;
}

.mainhead2 {
  font-size: 2vmax;
  font-weight: 600 !important;

}

.btn {
  /* border: 2px solid black; */
  background-color: #F45D48;
  border-radius: 500px;
  font-size: 2rem !important;
}

@keyframes shine {
  0% {
    background-position: 0% 50%;
  }

  50% {
    background-position: 100% 50%;
  }

  100% {
    background-position: 0% 50%;
  }
}

.date {
  font-size: 3vmax;
}

@media only screen and (max-width: 786px) {

  .landing {
    text-align: left;
    margin: 0 1rem;
  }

  .Landingimg-img {
    margin-top: 2rem;
  }

  .btn {
    border-radius: 500px;
    font-size: 1.25rem !important;
  }

  .topline {
    padding-top: 10vh;
  }
  
}
.navbar {
    background-color: rgb(244, 93, 72, 0.3);
    -webkit-backdrop-filter: blur(15px);
            backdrop-filter: blur(15px);
    color: #f8f5f2;
  }
  
  .nav-links {
    margin-right: 3rem;
  }
  
  .nav-name {
      color: #f45d48;
  }

  .nav-toggles {
    color: #078080 !important;
  }
  
  .nav-toggles:hover {
      transform: scale(1.1);
      transition: 0.2s ease;
  }
  
  @media only screen and (max-width: 786px)
  {
    .nav-name {
        display: none;
    }
  
    .nav-links {
      margin-right: unset;
    }
  
    .toggler {
      border: none;
    }
  }
  
.projects {
  position: relative;
}

.projects-text {
  position: absolute;
  font-size: 5rem;
  font-weight: 700 !important;
  transform: rotate(270deg);
  top: 40%;
  right: 80%;
  color: #078080;
  }
  

  .tba {
      font-size: 4rem;
      padding: 4rem;
  }
 
  #apply {
    border: 2px solid black;
    background-color: #f45d48;
  }  

  
  @media only screen and (max-width: 1400px)
  {
    .projects-text {
      position: unset;
      transform: rotate(0deg);
      font-size: 5rem;
      font-weight: 700 !important;
      color: #078080;
    }

    .tba {
      text-align: center;
      font-size: 3.5rem;
      padding: 0;
    }
  }

  @media only screen and (max-width: 786px)
{
    .projects-text {
      font-size: 3rem;
    }
}

  
  .flip-card-project-proj {
    background-color: transparent;
    width: 100%;
    height: 300px;
    perspective: 1000px;
  }
  
  .flip-card-inner-project {
    position: relative;
    width: 100%;
    height: 100%;
    text-align: left;
    transition: transform 0.6s;
    transform-style: preserve-3d;
  }
  
  /* .flip-card-project-proj:hover .flip-card-inner-project {
    transform: rotateY(180deg);
  } */
  
  .flip-card-front-project-fs, .flip-card-front-project-algo, .flip-card-front-project-ml, .flip-card-front-project-arvr, .flip-card-front-project-os, .flip-card-front-project-app, .flip-card-front-project-ai, .flip-card-back-project{
    position: absolute;
    width: 100%;
    height: 100%;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    border-radius: 20px;
  }
  
  .flip-card-front-project-fs {
    background: url(/static/media/Coding-project.0c00aaef.svg);
    background-color:  rgb(232, 145, 52) ;
    background-size: 100% 100%;
    color: white;
    padding: 1rem;
  }

  .flip-card-front-project-algo {
    background: url(/static/media/algorithm-project-01.1f045371.svg);
    background-color: #d4ecff;
    background-size: 100% 100%;
    color: white;
    padding: 1rem;
  }

  .flip-card-front-project-ml {
    background: url(/static/media/ML-project-01.67058f2b.svg);
    background-color: #f4413c;
    background-size: 100% 100%;
    color: white;
    padding: 1rem;
  }

  .flip-card-front-project-arvr {
    background: url(/static/media/ArVr-project-01.165c72c8.svg);
    background-color: #a430a5;
    background-size: 100% 100%;
    color: white;
    padding: 1rem;
  }

  .flip-card-front-project-os {
    background: url("/static/media/Operating system-project.9b62e819.svg");
    background-color: #c77309;
    background-size: 100% 100%;
    color: white;
    padding: 1rem;
  }

  .flip-card-front-project-app {
    background: url("/static/media/app development-project.98a94ff5.svg");
    background-color: #3bb4bc;
    background-size: 100% 100%;
    color: white;
    padding: 1rem;
  }

  .flip-card-front-project-ai {
    background: url("/static/media/Artificial intelligence-project.2ed835ab.svg");
    background-color: #7693a0;
    background-size: 100% 100%;
    color: white;
    padding: 1rem;
  }

  .flip-card-back-project {
    background: black;
    border: 2px solid white;
    color: white;
    padding: 3rem;
    transform: rotateY(180deg);
    font-family: 'DM Sans', sans-serif;
  }
  
  .header2 {
    position: relative;
    overflow: hidden;
  }
  
  .header2::before {
    position: absolute;
    top: 75%;
    right:15%;
    overflow: hidden;
    width: 80rem;
    height: 3px;
    content: '\a0';
    background: linear-gradient(-45deg, #ee7752, #e73c7e, #23a6d5, #23d5ab);
    background-size: 200% 200%;
    animation: gradient 5s ease infinite;
  }
  
  @media only screen and (max-width: 1200px)
  {
    .header2 {
      text-align: center !important;
    }
    .header2::before {
        display: none;
    }
  }
  
  @keyframes gradient {
    0% {
      background-position: 0% 50%;
    }
    50% {
      background-position: 100% 50%;
    }
    100% {
      background-position: 0% 50%;
    }
  }
  
  .mainhead1-project {
    
    color: white;
    font-weight: 700 !important;
    text-shadow: 4px 4px 2px black;
    -webkit-text-stroke-width: 1px;
    -webkit-text-stroke-color: black;
    font-size: 2vmax;
    text-align: center;
    /* font-weight: 600 !important; */
    position: absolute;
    top: 50%;
    left: 0%;
    transform: translateY(-65%);
  }

  .sawo {
    color: black;
  }

  .mainhead2-project {
    text-align: center;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
  }
  
  .svg-img {
  position: absolute;
  bottom: -10%;
  right: 0;
  }
  
  
  .svg-img1 {
  position: absolute;
  bottom: -10%;
  right: -15%;
  }
  
  @keyframes shine {
    0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
  }
  
  
  @media only screen and (max-width: 786px)
  {
  
  .flip-card-back-project h4 {
    font-size: 1.2rem;
  }
  
  .flip-card-back-project {
    padding: 2rem;
  }
  }
h3{
    color: #232323;
}

p{
    color:#222525;
}

i{
    color: #232323;
}

#copyright{
    height: 50px;
    /* background-color: #232323; */
}

.mdb-col {
    padding: 1rem 0;
    background-color: black;
}

.footer {
    background-color: black !important;
}
#about-para{
    line-height: 200%;
    text-align: center;
}

.about {
    height: 100%;
    padding: 5rem 0;
    position: relative;
    font-family: "DM Sans", sans-serif;
}

.pw-img {
    max-width: 35vmax;
    border-radius: 20px;
}

.para {
    color: #222525;
    font-size: 1.5rem;
}

.header-abt {
    position: absolute;
    font-size: 5rem;
    font-weight: 700 !important;
    transform: rotate(270deg);
    top: 40%;
    right: 85%;
    color: #078080;
}

.pro-img {
    margin: 5rem 2rem;
}

@keyframes gradient {
    0% {
      background-position: 0% 50%;
    }
    50% {
      background-position: 100% 50%;
    }
    100% {
      background-position: 0% 50%;
    }
  }
  

@media only screen and (max-width: 1400px)
{
    .header-abt {
        position: unset;
        transform: rotate(0deg);
        font-size: 5rem;
        font-weight: 700 !important;
        color: #078080;
    }
    .header1::before {
        display: none;
    }
}

@media only screen and (max-width: 786px)
{
    .header-abt {
      font-size: 3rem;
    }
}

.procedures {
    position: relative;
    font-family: "DM Sans", sans-serif;
    margin: 5rem 0;
}

.pro-img {
    margin-top: 5rem;
}

.pro-img-mob {
    display: none;
}

.proc-text {
    position: absolute;
    font-size: 5rem;
    font-weight: 700 !important;
    transform: rotate(270deg);
    top: 40%;
    right: 78%;
    color: #078080;
}

@media only screen and (max-width: 1400px) {
    .proc-text {
        position: unset;
        transform: rotate(0deg);
        font-size: 5rem;
        font-weight: 700 !important;
        color: #078080;
    }
}

@media only screen and (max-width: 786px) {
    .pro-para {
        font-size: 1.5rem;
    }
    .proc-text {
        font-size: 3rem;
    }

    .pro-img {
        display: none;

    }
    
    .pro-img-mob {
        margin-top: 3rem;
        display: block;
    }
}
.faq {
    /* background-color: black; */
    height: 100%;
    position: relative;
    font-family: 'DM Sans', sans-serif;
}

.accordion {
    padding: 1rem;
    text-align: left;
    font-size: 24px;
    cursor: pointer;
}

.card-acc {
    border: none;
    background-color: #f8f5f2;
}

.card-body-acc {
    
    
    background-size: 300% 300%;
    animation: gradient 5s ease infinite;
}

.acc-toggle {
    background: #f45d48;
    border-radius: 0.5rem !important;
    color: white;
    margin: 1rem 0;
}
.faq-text {
  position: absolute;
  font-size: 5rem;
  font-weight: 700 !important;
  transform: rotate(270deg);
  top: 30%;
  right: 85%;
  color: #078080;
}

.org-header {
  position: relative;
  overflow: hidden;
}

.org-header::before {
  position: absolute;
  top: 50%;
  right:23%;
  overflow: hidden;
  width: 50rem;
  height: 3px;
  content: '\a0';
  background: linear-gradient(-45deg, #ee7752, #e73c7e, #23a6d5, #23d5ab);
  background-size: 200% 200%;
  animation: gradient 5s ease infinite;
}

@media only screen and (max-width: 1200px)
{
  .org-header {
      text-align: center !important;
  }
  .org-header::before {
      display: none;
  }
}


@media only screen and (max-width: 1400px)
{
  .faq-text {
      position: unset;
      transform: rotate(0deg);
      font-size: 5rem;
      font-weight: 700 !important;
      color: #078080;
  }
}

@media only screen and (max-width: 786px)
{
    .faq-text {
      font-size: 3rem;
    }
}



@keyframes gradient {
    0% {
      background-position: 0% 50%;
    }
    50% {
      background-position: 100% 50%;
    }
    100% {
      background-position: 0% 50%;
    }
  }

@media only screen and (max-width: 786px) {
    .accordion {
        padding: 2rem 0;
        font-size: 16px;
    }
}
#timeline{
    line-height: 200%;
}

.timeline {
    background-color: #f8f5f2;
    color: #222525;
    position: relative;
}

.imaze {
    width: 100%;
}

.header {
  position: absolute;
  font-size: 5rem;
  font-weight: 700 !important;
  transform: rotate(270deg);
  top: 45%;
  right: 80%;
  color: #078080;
}

@keyframes gradient {
    0% {
      background-position: 0% 50%;
    }
    50% {
      background-position: 100% 50%;
    }
    100% {
      background-position: 0% 50%;
    }
  }
  

@media only screen and (max-width: 1400px)
{
  .para {
    text-align: center !important;
  }
    .header {
      position: unset;
      transform: rotate(0deg);
      font-size: 5rem;
      font-weight: 700 !important;
      color: #078080;
    }
    .header::after {
        display: none;
    }
}

@media only screen and (max-width: 786px)
{
    .header {
      font-size: 3rem;
    }
}

.forms-body {
  /* display: flex; */
  height: 90vh;
  background: linear-gradient(135deg, #f8f5f2, #f45d48);
  padding: 10px;
  font-family: "Poppins", sans-serif;
  margin: auto;
}

.label-text {
  font-size: 1.25rem;
  font-family: "Poppins", sans-serif;
}

.form-control {
  padding: 1.5rem;
  border-radius: 0.75rem;
  font-family: "Poppins", sans-serif;
  border: 2px solid rgb(172, 172, 172);
  font-size: 1.25rem;
}

.con-baap {
  background: linear-gradient(135deg, #f8f5f2, #f45d48);
  padding : 8rem 0;
}

.containers {
  width: 60%;
  background-color: #fff;
  padding: 25px 30px;
  border-radius: 10px;
  text-align: left;
  margin: auto;
}

.reg-btn {
  border: 2px transparent solid;
  width: 100%;
  font-family: "Poppins", sans-serif;
  font-size: 1.5rem !important;
  background-color: #078080;
}

.reg-btn:hover{
  background-color: white;
  color: black;
  border: #078080 2px solid;
}

.drop-sel {
  width: 100%;
  padding: 0.5rem;
  font-size: 1.25rem;
  font-family: "Poppins", sans-serif;
  border-radius: 1rem;
  color: rgb(73, 73, 73);
}

.containers .title {
  font-size: 26px;
  font-weight: 600;
  font-size: 2rem;
  position: relative;
  text-align: left;
}

.containers .title::before {
  content: " ";
  position: absolute;
  left: 0;
  bottom: 0;
  height: 3px;
  width: 30px;
  background: linear-gradient(135deg,  #f8f5f2, #078080);
}

.containers form .user-details {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin: 20px 0 12px 0;
}

.details {
  text-align: left;
}
.success-message {
  margin: 10px 0;
  color: #61b15a;
  font-weight: 400;
}
.alert-message {
  color: red;
  font-size: 10px;
  transition: all 0.3s ease;
}
form .user-details .input-box {
  width: calc(100% / 2 - 20px);
  margin-bottom: 15px;
}
.user-details .input-box input {
  height: 45px;
  width: 100%;
  outline: none;
  border-radius: 5px;
  border: 1px solid #ccc;
  padding-left: 15px;
  font-size: 16px;
  border-bottom-width: 2px;
  transition: all 0.3s ease;
}
.user-details .input-box .details {
  font-weight: 500;
  margin-bottom: 5px;
  display: block;
}

.user-details .input-box input:focus {
  border-color: #03256c;
}

.form-button {
  width: 100%;
  height: 100%;
  border: none;
  color: #fff;
  font-weight: 600;
  letter-spacing: 1px;
  border-radius: 5px;
  background: linear-gradient(135deg,  #f8f5f2, #078080);
  padding: 10px 0px;
}
.form-button:hover {
  background: linear-gradient(-135deg,  #f8f5f2, #078080);
}

@media only screen and (max-width: 786px) {
  .containers {
    width: 90%;
  }
  form .user-details .input-box {
    width: 100%;
    margin-bottom: 15px;
  }
  .containers form .user-details {
    max-height: 300px;
    overflow-y: scroll;
  }
  .user-details::-webkit-scrollbar {
    width: 0;
  }
}
.domains {
  position: relative;
}

.domains-text {
  position: absolute;
  font-size: 5rem;
  font-weight: 700 !important;
  transform: rotate(270deg);
  top: 45%;
  right: 80%;
  color: #078080;
  }

  .dom-img {
    width: 70%;
    height: 70%;
    display: flex;
    margin: 0.5rem;
  }
  
  .dom-text {
    position: absolute;
    top: 75%;
    left: 55%;
    transform: translate(-50%, -50%);
    font-weight: 600 !important;
  }

  .tba {
      font-size: 4rem;
      padding: 4rem;
  }
 
  #apply {
    border: 2px solid black;
    background-color: #f45d48;
  }  

  
  @media only screen and (max-width: 1400px)
  {
    .domains-text {
      position: unset;
      transform: rotate(0deg);
      font-size: 5rem;
      font-weight: 700 !important;
      color: #078080;
    }

    .tba {
      text-align: center;
      font-size: 3.5rem;
      padding: 0;
    }
  }

  @media only screen and (max-width: 786px)
{
    .domains-text {
      font-size: 3rem;
    }
}

  
  .flip-card-project {
    border: 2px solid black;
    height: 280px;
    border-radius: 1rem;
    background: linear-gradient(-45deg,  #f8f5f2 0%, #f45d48 100%);
  }

.rules {
    /* background-color: black; */
    height: 100%;
    position: relative;
    font-family: 'DM Sans', sans-serif;
}

.accordion {
    padding: 1rem;
    text-align: left;
    font-size: 24px;
    cursor: pointer;
}

.card-acc {
    border: none;
    background-color: #f8f5f2;
}

.card-body-acc {
    
    
    background-size: 300% 300%;
    animation: gradient 5s ease infinite;
}

.acc-toggle {
    background: #f45d48;
    border-radius: 0.5rem !important;
    color: white;
    margin: 1rem 0;
}
.rules-text {
  position: absolute;
  font-size: 5rem;
  font-weight: 700 !important;
  transform: rotate(270deg);
  top: 30%;
  right: 85%;
  color: #078080;
}

.org-header {
  position: relative;
  overflow: hidden;
}

.org-header::before {
  position: absolute;
  top: 50%;
  right:23%;
  overflow: hidden;
  width: 50rem;
  height: 3px;
  content: '\a0';
  background: linear-gradient(-45deg, #ee7752, #e73c7e, #23a6d5, #23d5ab);
  background-size: 200% 200%;
  animation: gradient 5s ease infinite;
}

@media only screen and (max-width: 1200px)
{
  .org-header {
      text-align: center !important;
  }
  .org-header::before {
      display: none;
  }
}


@media only screen and (max-width: 1400px)
{
  .rules-text {
      position: unset;
      transform: rotate(0deg);
      font-size: 5rem;
      font-weight: 700 !important;
      color: #078080;
  }
}

@media only screen and (max-width: 786px)
{
    .rules-text {
      font-size: 3rem;
    }
}



@keyframes gradient {
    0% {
      background-position: 0% 50%;
    }
    50% {
      background-position: 100% 50%;
    }
    100% {
      background-position: 0% 50%;
    }
  }

@media only screen and (max-width: 786px) {
    .accordion {
        padding: 2rem 0;
        font-size: 16px;
    }
}
#perks-para{
    line-height: 200%;
    text-align: center;
}

.perks {
    height: 100%;
    padding: 5rem 0;
    position: relative;
    font-family: "DM Sans", sans-serif;
}

.pw-img {
    max-width: 35vmax;
    border-radius: 20px;
}

.para {
    color: #222525;
    font-size: 1.5rem;
}

.header1 {
    position: absolute;
    font-size: 5rem;
    font-weight: 700 !important;
    transform: rotate(270deg);
    top: 40%;
    right: 85%;
    color: #078080;
}

.pro-img {
    margin: 5rem 2rem;
}


.pro-text {
    position: absolute;
    font-size: 5rem;
    font-weight: 700 !important;
    transform: rotate(270deg);
    top: 40%;
    right: 78%;
    color: #078080;
}



@keyframes gradient {
    0% {
      background-position: 0% 50%;
    }
    50% {
      background-position: 100% 50%;
    }
    100% {
      background-position: 0% 50%;
    }
  }
  

@media only screen and (max-width: 1200px)
{
    .header1 {
        text-align: center !important;
    }
    .header1::before {
        display: none;
    }
}
