#about-para{
    line-height: 200%;
    text-align: center;
}

.about {
    height: 100%;
    padding: 5rem 0;
    position: relative;
    font-family: "DM Sans", sans-serif;
}

.pw-img {
    max-width: 35vmax;
    border-radius: 20px;
}

.para {
    color: #222525;
    font-size: 1.5rem;
}

.header-abt {
    position: absolute;
    font-size: 5rem;
    font-weight: 700 !important;
    transform: rotate(270deg);
    top: 40%;
    right: 85%;
    color: #078080;
}

.pro-img {
    margin: 5rem 2rem;
}

@keyframes gradient {
    0% {
      background-position: 0% 50%;
    }
    50% {
      background-position: 100% 50%;
    }
    100% {
      background-position: 0% 50%;
    }
  }
  

@media only screen and (max-width: 1400px)
{
    .header-abt {
        position: unset;
        transform: rotate(0deg);
        font-size: 5rem;
        font-weight: 700 !important;
        color: #078080;
    }
    .header1::before {
        display: none;
    }
}

@media only screen and (max-width: 786px)
{
    .header-abt {
      font-size: 3rem;
    }
}
